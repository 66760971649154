<template>
    <div>
        <div class="holder">
            <div class="top-holder">
                <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>
                <div>
                    <button @click="movetoTenantAgreement()" class="btn"><img src="../assets/icons/export-upload.svg" alt="export"> Tenancy Agreement</button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-8">
                    <div class="unit-header">
                    <div class="initials">
                        <h6>G</h6>
                    </div>
                    <div>
                        <h4>Unit 1A <span class="occupied">Occupied</span></h4>
                        <h5>44, Gana Street, Maitama, Abuja, FCT</h5>
                    </div>
                </div>
                <hr>

                <div>
                    <div class="d-flex justify-content-between">
                        <h4 class="text-header">Unit Info.</h4>

                        <span style="cursor: pointer;" @click="showUnitInfo = !showUnitInfo"><i class="fa" :class="[showUnitInfo ? 'fa-chevron-up' : 'fa-chevron-down']"></i></span>
                    </div>
                    <div class="row mt-3" v-if="showUnitInfo">
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Rental Price</h6>
                                <h5>₦500,000/annually</h5>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Type of rent</h6>
                                <h5>Guaranteed rent</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Payment plans</h6>
                                <h5>Annually</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Bedrooms</h6>
                                <h5>4</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Bathrooms</h6>
                                <h5>3</h5>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>

                <div>
                    <div class="d-flex justify-content-between">
                        <h4 class="text-header">Tenant details</h4>

                        <span style="cursor: pointer;" @click="showTenantDetails = !showTenantDetails"><i class="fa" :class="[showTenantDetails ? 'fa-chevron-up' : 'fa-chevron-down']"></i></span>
                    </div>
                    <div class="row mt-3" v-if="showTenantDetails">
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Name</h6>
                                <h5>Jane Doe</h5>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Email</h6>
                                <h5>janedoe@gmail.com</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Phone number</h6>
                                <h5>08037633322</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Rental start and end date</h6>
                                <h5>Ongoing</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Next payment date</h6>
                                <h5>Mar 15, 2025</h5>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="itmes-data">
                                <h6>Outstanding balance</h6>
                                <h5>None</h5>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="right-holder">
                        <h4 class="text-header">Payment history</h4>
                        <hr>
                        <div class="payment-history">
                            <div>
                                <h4>Cash deposit</h4>
                                <h5>Feb 12, 2025</h5>
                            </div>
                            <h6>₦450,000</h6>
                        </div>
                        <hr>
                        <div class="payment-history">
                            <div>
                                <h4>Cash deposit</h4>
                                <h5>Feb 12, 2025</h5>
                            </div>
                            <h6>₦450,000</h6>
                        </div>
                        <hr>
                        <div class="payment-history">
                            <div>
                                <h4>Cash deposit</h4>
                                <h5>Feb 12, 2025</h5>
                            </div>
                            <h6>₦450,000</h6>
                        </div>
                        <hr>
                        <div class="payment-history">
                            <div>
                                <h4>Cash deposit</h4>
                                <h5>Feb 12, 2025</h5>
                            </div>
                            <h6>₦450,000</h6>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "UnitDetails",
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            showUnitInfo: false,
            showTenantDetails: false,
        }
    },
    methods: {
        movetoTenantAgreement() {
            this.$router.push({path: `/user/partner/tenant-agreement`});
        }
    },
}
</script>

<style scoped lang="scss">
.top-holder {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    button {
        img {
            padding-right: 10px;
        }
        border: 1px solid #D5D7DA;
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        color: #414651;
        border-radius: 6px;
        outline: 0;
        box-shadow: none;
    }
}
.holder {
    background: #ffffff;
    padding: 30px 15px;
}
.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}

.unit-header {
    display: flex;
    gap: 10px;
    .initials {
        height: 56px;
        width: 56px;
        background: #FAFAFA;
        border-radius: 50px;
        text-align: center;
    }
    h6 {
       
        color: #2B3352;
        font-family: Lato;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
        text-align: center;
        padding: 15px;
    }
    h4 {
        font-family: Lato;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        color: #2B3352;
        span  {
            margin-left: 15px;
        }
    }
    h5 {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555C74;
    }
}

.occupied {
    color: #B42318;
    font-family: Lato;
    font-weight: 500;
    font-size: 11px;
    background: #FEF3F2;
    border: 1px solid #FECDCA;
    border-radius: 50px;
    padding: 4px 6px;

}
.vacant {
    color: #067647;
    font-family: Lato;
    font-weight: 500;
    font-size: 11px;
    background: #ECFDF3;
    border: 1px solid #ABEFC6;
    border-radius: 50px;
    padding: 4px 6px;
}

.text-header {
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2B3352;
}

.itmes-data {
    margin-bottom: 30px;
    h5 {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        color: #555C74;
    }
    h6 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2B3352;
    }
}

.right-holder {
    border-left: 1px solid #E9EAEB;
    padding-left: 15px;
}
.payment-history {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4, h5 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    h4 {
        font-weight: 500;
        color: #2B3352;
    }
    h5 {
        font-weight: 400;
        color: #555C74;
    }
}

@media screen and (max-width: 599px) {
    .right-holder {
        margin-top: 40px;
        border-left: none;
        padding-left: none
    }
}
</style>