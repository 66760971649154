var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"holder"},[_c('div',{staticClass:"top-holder"},[_c('p',{staticClass:"back-text",on:{"click":function($event){return _vm.backBtn()}}},[_vm._m(0),_vm._v("Back")]),_c('div',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.movetoTenantAgreement()}}},[_c('img',{attrs:{"src":require("../assets/icons/export-upload.svg"),"alt":"export"}}),_vm._v(" Tenancy Agreement")])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_vm._m(1),_c('hr'),_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',{staticClass:"text-header"},[_vm._v("Unit Info.")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showUnitInfo = !_vm.showUnitInfo}}},[_c('i',{staticClass:"fa",class:[_vm.showUnitInfo ? 'fa-chevron-up' : 'fa-chevron-down']})])]),(_vm.showUnitInfo)?_c('div',{staticClass:"row mt-3"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e(),_c('hr')]),_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h4',{staticClass:"text-header"},[_vm._v("Tenant details")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showTenantDetails = !_vm.showTenantDetails}}},[_c('i',{staticClass:"fa",class:[_vm.showTenantDetails ? 'fa-chevron-up' : 'fa-chevron-down']})])]),(_vm.showTenantDetails)?_c('div',{staticClass:"row mt-3"},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]):_vm._e(),_c('hr')])]),_vm._m(13)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa fa-chevron-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"unit-header"},[_c('div',{staticClass:"initials"},[_c('h6',[_vm._v("G")])]),_c('div',[_c('h4',[_vm._v("Unit 1A "),_c('span',{staticClass:"occupied"},[_vm._v("Occupied")])]),_c('h5',[_vm._v("44, Gana Street, Maitama, Abuja, FCT")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Rental Price")]),_c('h5',[_vm._v("₦500,000/annually")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Type of rent")]),_c('h5',[_vm._v("Guaranteed rent")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Payment plans")]),_c('h5',[_vm._v("Annually")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Bedrooms")]),_c('h5',[_vm._v("4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Bathrooms")]),_c('h5',[_vm._v("3")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Name")]),_c('h5',[_vm._v("Jane Doe")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Email")]),_c('h5',[_vm._v("janedoe@gmail.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Phone number")]),_c('h5',[_vm._v("08037633322")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Rental start and end date")]),_c('h5',[_vm._v("Ongoing")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Next payment date")]),_c('h5',[_vm._v("Mar 15, 2025")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"itmes-data"},[_c('h6',[_vm._v("Outstanding balance")]),_c('h5',[_vm._v("None")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"right-holder"},[_c('h4',{staticClass:"text-header"},[_vm._v("Payment history")]),_c('hr'),_c('div',{staticClass:"payment-history"},[_c('div',[_c('h4',[_vm._v("Cash deposit")]),_c('h5',[_vm._v("Feb 12, 2025")])]),_c('h6',[_vm._v("₦450,000")])]),_c('hr'),_c('div',{staticClass:"payment-history"},[_c('div',[_c('h4',[_vm._v("Cash deposit")]),_c('h5',[_vm._v("Feb 12, 2025")])]),_c('h6',[_vm._v("₦450,000")])]),_c('hr'),_c('div',{staticClass:"payment-history"},[_c('div',[_c('h4',[_vm._v("Cash deposit")]),_c('h5',[_vm._v("Feb 12, 2025")])]),_c('h6',[_vm._v("₦450,000")])]),_c('hr'),_c('div',{staticClass:"payment-history"},[_c('div',[_c('h4',[_vm._v("Cash deposit")]),_c('h5',[_vm._v("Feb 12, 2025")])]),_c('h6',[_vm._v("₦450,000")])]),_c('hr')])])
}]

export { render, staticRenderFns }